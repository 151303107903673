import { config } from 'src/app/developer/smoke-test/config/config.qa';
export const environment = {
  features: {
    sms: false,
    UW_AdditionalInfo: true,
    developerNavigation: true,
    clarity: true,
    useSelfService: true,
  },
  production: true,
  clarity: 'e4g0q2c7kl',
  config: {
    apis: {
      callzone: 'https://vcall-gateway-qa.ushealthgroup.com/api/callzone/v1',
      vcall: 'https://vcall-gateway-qa.ushealthgroup.com/api/vcall/v1',
      legacyContact: 'https://vcall-gateway-qa.ushealthgroup.com/api/legacycontact/v1',
      usps: 'https://vcall-gateway-qa.ushealthgroup.com/api/usps/v1',
      prescription:
        'https://vcall-gateway-qa.ushealthgroup.com/api/medicalsearch/v1/medicationlookup',
      ezapp: 'https://vcall-gateway-qa.ushealthgroup.com/api/ezapp/v1/',
      logger: 'https://vcall-gateway-qa.ushealthgroup.com/api',
      rxProfile: 'https://vcall-gateway-qa.ushealthgroup.com/api/rxprofile/v1',
      efulfillment:
        'https://vcall-gateway-qa.ushealthgroup.com/api/efulfillment/v1',
      search: 'https://vcall-gateway-qa.ushealthgroup.com/api/',
      providerLookup:
        'https://vcall-gateway-qa.ushealthgroup.com/api/medicalsearch/v2/providerlookup',
      secureEmail:
        'https://nbc-gateway-qa.ushealthgroup.com/billing/v1/sendemail',
    },
    environment: 'qa',
    adRoles:
      'TST_VCall2_HQSysAdmin, TST_VCall2_ReadOnly, TST_VCall2_HQSupervisor, TST_VCall2_CCSupervisor, TST_VCall2_HQCCVerifiers, TST_VCall2_HQUnderwriter, PRD_VCall2_HQSysAdmin, PRD_VCall2_ReadOnly, PRD_VCall2_HQSupervisor, PRD_VCall2_CCSupervisor, PRD_VCall2_HQCCVerifiers, PRD_VCall2_HQUnderwriter, PRD_VCall2_HQSupervisor_Vendors, PRD_VCall2_HQCCVerifiers_Vendors, PRD_VCall2_CCSupervisor_Vendors',
    callZoneStatusConfiguration: {
      voicemailModelNoShow: {
        statusId: 2,
        stopcallId: 0,
      },
      voicemailModelWrongNumber: {
        statusId: 4,
      },
      stopCall: {
        statusId: 3,
      },
      // change the values if below are not correct
      stopCallReasonForReschedule: {
        stopCallId: 20,
        stopCallLabel: 'Applicant Asked Verifier To Reschedule The Call',
      },
    },
  },
  baseApplicationDocumentUrl:
    'https://vcall-gateway-qa.ushealthgroup.com/api/ezapp/v1',
  graphqluri: 'https://nbc-gateway-qa.ushealthgroup.com/api/graphql',
  apsApiUrl: 'https://nbc-gateway-qa.ushealthgroup.com',
  authConfig: {
    tenant: 'cc7c826f-6444-4c0e-8314-83afb87c98ea',
    clientId: 'f76b3bd2-96f0-4eca-bda9-3cf6d99adc94',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: 'http://localhost:2025/home',
    addedProtectedResourceMap: [
      {
        key: 'https://vcall-gateway-qa.ushealthgroup.com/api',
        list: ['api://6e794aee-8e8b-4e19-8f07-124afd39a89b/user.execute'],
      },
      {
        key: 'https://nbc-gateway-qa.ushealthgroup.com',
        list: ['api://6e794aee-8e8b-4e19-8f07-124afd39a89b/user.execute'],
      }
    ],
    cacheLocation: 'localStorage',
  },
  instrumentationKey: '337879cd-667e-428c-9c9a-dc58561c89a0',
  listOfApisAndServicesForSmokeTest: config.listOfServicesWithApis,
  resourceIdsList: '[3628,3626,3633,3627,3652,3673]',

  callzoneAppointmentUrl:
    'https://stage.ushg.cc/Callzone/ListUpcomingAppointments.aspx',
  rescheduleAppointmentUrl:
    'https://cz-stage.ushealthgroup.com/Appointment_CS/SchedulingiFrame.aspx',
  selfServiceUrl: 'https://apss-qa.ushealthgroup.com',
  userguideConfig:
    'https://stdiagmi01scus01prod.blob.core.windows.net/vc2-training/data.json?sp=r&st=2022-02-03T17:05:40Z&se=2032-02-04T01:05:40Z&spr=https&sv=2020-08-04&sr=b&sig=4%2FdRGKaCxpCnrr3iLZ4BtiL0ptv6%2FAVenL7jWRn33rw%3D',

  releaseNotesConfigUrl:
    'https://stdiagmi01scus01prod.blob.core.windows.net/vc2-training/release.json?sp=r&st=2022-02-03T16:00:19Z&se=2032-02-04T00:00:19Z&spr=https&sv=2020-08-04&sr=b&sig=yMLndjsKqjm5Fnnak1BZNjRXBeG3fI%2FBdnuc1%2F8oPmY%3D',

  businessReleaseNotesConfigUrl:
    'https://stdiagmi01scus01prod.blob.core.windows.net/vc2-training/business-release.json?sp=r&st=2022-02-03T15:59:19Z&se=2032-02-03T23:59:19Z&spr=https&sv=2020-08-04&sr=b&sig=9jK8lJm2ZjpGW5oexrX1Iuw2rytuoLp8qrMMDw61H9k%3D',
  viewmode: {
    review: ['review', 'vCall', 'ezApp', 'recalls'],
    aps: ['review', 'vCall', 'ezApp', 'recalls', 'apsrequests'],
    addOns: ['review', 'vCall', 'ezApp', 'recalls', 'addOns'],
    default: ['review', 'vCall', 'ezApp', 'recalls'],
  },
  productAppTypes: [12, 13, 17, 25],

  allowedBrowsers: {
    browsers: ['Google Chrome', 'Microsoft Edge'],
  },
};
